.apply-root {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 60px;
}

.apply-root .search-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.apply-root .search-section .name-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.apply-root .search-section .name-input .csearch-input-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.apply-root .search-section .name-input .csearch-input-section .search-input {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.apply-root .list-section {
  margin-top: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid #dbdbdb;
}

.apply-root .list-section .header-article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: #dedede;
  padding: 16px 0px;
}

.apply-root .list-section .header-article .header-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 20px;
  font-weight: bold;
}

.apply-root .list-section .body-article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.apply-root .list-section .body-article .list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: #ffffff;
  border-bottom: 1px solid #dedede;
  padding: 16px 0px;
}

.apply-root .list-section .body-article .list-item .item-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
