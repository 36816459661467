.qna-root {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.qna-root .title-section {
  background-color: #fff;
  height: 120px;
  padding-bottom: 30px;
  padding-left: 60px;
}

.qna-root .title-section .title {
  font-size: 28px;
  font-weight: bolder;
  color: #333333;
  margin-top: 60px;
}

.qna-root .content-section {
  padding: 60px;
}

.qna-root .content-section .list-section {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
}

.qna-root .content-section .list-section .qna-card-item {
  background-color: #fff;
  padding: 36px 20px;
  margin: 20px;
}

.qna-root .content-section .list-section .qna-card-item .qna-title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #333333;
}

.qna-root .content-section .list-section .qna-card-item .content {
  font-size: 20px;
  font-weight: 500;
  color: #888888;
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.qna-root .content-section .list-section .qna-card-item .answer-button {
  border: solid 1px #b5b5b5;
  background-color: #f5f5f5;
  color: #333333;
  font-size: 20px;
  text-align: center;
  height: 48px;
  width: 100%;
}

.qna-root .content-section .add-button {
  position: fixed;
  bottom: 60px;
  right: 80px;
  width: 160px;
  height: 160px;
  border-radius: 80px;
  background-color: #333333;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.qna-root .content-section .add-button span {
  font-size: 28px;
}
