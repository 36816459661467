.request-detail-root {
  flex: 1;
  padding: 60px;

  .info-section {
    display: flex;
    flex-direction: column;

    .title-article {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .title {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
      }
    }

    .content-article {
      display: flex;
      flex-direction: row;

      .info-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 68px;

        .label-item {
          margin-top: 12px;

          .label-title {
            font-size: 21px;
            font-weight: bold;
            color: #333333;
          }

          .label-content {
            font-size: 21px;
            font-weight: bold;
            color: #888888;
          }
        }
      }
    }
  }
}
