.admin-create-root {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.admin-create-root .title-section {
  height: 120px;
  padding: 0px 60px 30px 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.admin-create-root .title-section .title {
  color: #333333;
  font-weight: bolder;
  font-size: 28px;
}

.admin-create-root .title-section .button-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.admin-create-root .title-section .button-wrapper button {
  font-size: 20px;
  height: 60px;
  font-weight: bold;
  padding: 0px 20px;
}

.admin-create-root .title-section .button-wrapper .cancel-button {
  border: solid 1px #333333;
  color: #333333;
  background-color: #fff;
  margin-right: 8px;
}

.admin-create-root .content-section {
  padding: 60px;
  overflow: visible;
}

.admin-create-root .content-section .admin-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
}

.admin-create-root .content-section .admin-form .input-section {
  margin-right: 144px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: visible;
}

.admin-create-root .content-section .admin-form .input-section .input-item {
  margin-bottom: 48px;
  overflow: visible;
}

.admin-create-root .content-section .admin-form .input-section .input-item .input-label {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
}

.admin-create-root .content-section .admin-form .input-section .input-item .input {
  padding: 16px 24px;
  width: 100%;
  border: solid 2px #b5b5b5;
  background-color: #fff;
}

.admin-create-root .content-section .admin-form .input-section .input-item .input::-webkit-input-placeholder {
  color: #888888;
  font-size: 16px;
}

.admin-create-root .content-section .admin-form .input-section .input-item .input:-ms-input-placeholder {
  color: #888888;
  font-size: 16px;
}

.admin-create-root .content-section .admin-form .input-section .input-item .input::-ms-input-placeholder {
  color: #888888;
  font-size: 16px;
}

.admin-create-root .content-section .admin-form .input-section .input-item .input::placeholder {
  color: #888888;
  font-size: 16px;
}

.admin-create-root .content-section .admin-form .profile-section {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: visible;
}

.admin-create-root .content-section .admin-form .profile-section .input-item {
  margin-bottom: 48px;
  overflow: visible;
}

.admin-create-root .content-section .admin-form .profile-section .input-item .input-label {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
}

.admin-create-root .content-section .admin-form .profile-section .input-item .profile-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.admin-create-root .content-section .admin-form .profile-section .input-item .profile-wrapper #profile-input {
  display: none;
}

.admin-create-root .content-section .admin-form .profile-section .input-item .profile-wrapper img {
  width: 56px;
  height: 56px;
  border-radius: 28px;
}

.admin-create-root .content-section .admin-form .profile-section .input-item .profile-wrapper label {
  border: solid 1px #333;
  background-color: #f5f5f5;
  font-size: 16px;
  color: #333333;
  text-align: center;
  padding: 8px 12px;
  margin-left: 24px;
}

.admin-create-root .content-section .admin-form .profile-section .store-button {
  border-radius: 2px;
  background-color: #333333;
  color: #ffffff;
  width: 110px;
  height: 60px;
  font-size: 20px;
  font-weight: bold;
}
