.admin-root {
  flex: 1;
  display: flex;
  flex-direction: column;

  .title-section {
    height: 120px;
    background-color: #fff;

    .title {
      color: #333333;
      font-size: 28px;
      margin-left: 60px;
      margin-top: 60px;
      font-weight: bolder;
    }
  }

  .content-section {
    padding: 60px;

    .search-input {
      width: 100%;
    }

    .list-section {
      margin-top: 60px;

      .list-header {
        display: flex;
        background-color: #dedede;
        color: #333333;
        padding: 16px 0px;
        border: solid 1px #b5b5b5;
        .header-col {
          flex: 1;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
        }
      }
      .list-body {
        background-color: #fff;
        border: solid 1px #b5b5b5;

        .list-row {
          padding: 10px 0px;
          display: flex;
          align-items: center;

          &:not(:last-child) {
            border-bottom: solid 1px #b5b5b5;
          }

          .row-item {
            flex: 1;
            text-align: center;
            font-size: 16px;

            img {
              width: 36px;
              height: 36px;
              border-radius: 18px;
            }
          }
        }
      }
    }
    .create-button {
      margin-top: 40px;
      float: right;
      padding: 20px;
      background-color: #333333;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
