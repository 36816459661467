.calc-item-root {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 60px;
}

.calc-item-root .intro-section {
  white-space: pre-line;
}

.calc-item-root .intro-section .title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.calc-item-root .intro-section .content {
  font-size: 18px;
  color: #333333;
  margin-top: 20px;
  padding: 28px;
  border: solid 1px #b5b5b5;
  background-color: #fff;
}

.calc-item-root .item-list-section .section-add-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #f5f5f5;
  border: 1px solid #707070;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 80px;
  margin: 15px;
  margin-top: 90px;
  font-size: 30px;
  color: #333333;
}
