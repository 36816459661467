.request-detail-root {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 60px;
}

.request-detail-root .info-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.request-detail-root .info-section .title-article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.request-detail-root .info-section .title-article .title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.request-detail-root .info-section .content-article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.request-detail-root .info-section .content-article .info-item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 68px;
}

.request-detail-root .info-section .content-article .info-item .label-item {
  margin-top: 12px;
}

.request-detail-root .info-section .content-article .info-item .label-item .label-title {
  font-size: 21px;
  font-weight: bold;
  color: #333333;
}

.request-detail-root .info-section .content-article .info-item .label-item .label-content {
  font-size: 21px;
  font-weight: bold;
  color: #888888;
}
