.gnb-root {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;

  .gnb-list-section {
    width: 300px;
    margin-top: 120px;

    .gnb-item {
      cursor: pointer;
      color: #888888;
      font-size: 18px;
      padding: 24px 0px;
      padding-left: 60px;
      position: relative;

      &.selected {
        color: #2527ff;
        background-color: #2527ff22;

        .bar {
          width: 8px;
          height: 70px;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
          background-color: #2527ff;
        }
      }
    }
  }
}
