.card-news-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .title-article {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;

    .title {
      width: fit-content;
      font-size: 32px;
      font-weight: 600;
    }

    .sub-title {
      font-size: 24px;
      color: #888888;
      margin: 2rem;
    }

    @media (max-width: 760px) {
      margin-top: 40px;

      .title {
        font-size: 24px;
      }

      .sub-title {
        font-size: 18px;
      }
    }

    @media (max-width: 380px) {
      .title {
        font-size: 20px;
      }

      .sub-title {
        font-size: 14px;
      }
    }
  }

  .card-news-row {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 1020px) {
      flex-direction: column-reverse;
    }

    .card-news-column {
      margin: 0px 20px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
