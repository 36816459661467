.main-banner-root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .content-title-section {
    padding: 30px;
    font-size: 28px;
    font-weight: 800;
    color: #333333;
    background-color: #ffffff;
  }

  .preview-section {
    height: 400px;
    display: flex;
    flex-direction: row;
    padding: 60px;

    .image-video-article {
      flex: 1;

      .title {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
      }

      .image-video {
        margin-top: 24px;
      }

      img,
      video {
        width: 100%;
      }
    }

    .url-article {
      flex: 1;

      .title {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
      }

      .url {
        flex: 1;
        display: flex;
        flex-direction: row;
        margin-top: 24px;

        .url-input {
          height: 48px;
          margin-right: 16px;
          flex: 1;
        }

        .url-btn {
          width: 110px;
          height: 48px;
          border-radius: 2px;
          border: solid 1px #707070;
          background-color: #ffffff;
          font-size: 20px;
          color: #333333;
        }
      }
    }
  }

  .list-section {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: 60px;

    .file-list {
      flex: 1;
      margin-right: 60px;

      .search-article {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title {
          font-size: 24px;
          color: #333333;
        }

        .date-input {
          height: 48px;
          width: 200px;
          margin-left: 12px;
        }

        .file-name-input {
          height: 48px;
          margin-right: 12px;
          width: 300px;
        }

        .search-btn {
          width: 110px;
          height: 48px;
          border-radius: 2px;
          border: solid 1px #707070;
          background-color: #fff;
          font-size: 20px;
          color: #333333;
        }
      }

      .list-article {
        .list-header {
          height: 56px;
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: #dedede;
          margin-top: 20px;
          border: 1px solid #b5b5b5;

          .list-header-item {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .list-body {
          display: flex;
          flex-direction: column;

          .list-item {
            display: flex;
            flex-direction: row;
            height: 60px;
            align-items: center;
            background-color: #fff;
            border: 1px solid #c6c6c6;

            .list-item-label {
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 1;
            }
          }
        }

        .list-footer {
        }
      }
    }

    .file-input {
      display: flex;
      flex-direction: column;
      width: 420px;
      height: 420px;
      padding: 20px;
      border: solid 1px #b5b5b5;
      background-color: #fff;

      .input-item {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        color: #333333;
        margin-top: 24px;

        .input {
          height: 48px;
          margin-top: 14px;
        }
      }

      .input-btn {
        margin-top: 24px;
        height: 48px;
        border-radius: 2px;
        border: solid 1px #707070;
        background-color: #ffffff;
        font-size: 20px;
        color: #333333;
      }
    }
  }
}
