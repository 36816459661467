.post-select-root {
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 60px;
  position: relative;
}

.post-select-root .input-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.post-select-root .input-section .space {
  width: 60px;
}

.post-select-root .post-list-container {
  max-height: 500px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  margin-top: 48px;
}
