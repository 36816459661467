.qna-create-root {
  background-color: #f5f5f5;
  padding: 60px;
  width: 1024px;

  .section-label {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 16px;
  }

  .title-content {
    padding: 8px 20px;
    color: #888888;
    font-size: 20px;
    width: 100%;
    background-color: #fff;
    border: solid 1px #333;
    margin-bottom: 36px;
  }

  .content-input {
    margin-bottom: 36px;
    min-height: 200px;
    height: auto;
    padding: 8px 20px;
    color: #888888;
    font-size: 20px;
    width: 100%;
    background-color: #fff;
    border: solid 1px #333;
  }

  .button-wrapper {
    display: flex;
    height: 74px;
    margin-top: 48px;
    button {
      flex: 1;
      font-size: 20px;

      &:first-child {
        margin-right: 12px;
        background-color: #fff;
        border: solid 1px #333;
        color: #333;
      }

      &:last-child {
        background-color: #333;
        color: #fff;
      }
    }
  }
}
