.post-root {
  flex: 1;
  padding: 60px;

  .input-section {
    overflow: visible;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .space {
      width: 60px;
    }

    .post-input {
      width: 600px;
    }
  }

  .post-list-section {
    margin-top: 28px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }
}
