.card-news-root {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 25vw;
  height: 20vw;
  border-radius: 2vw;
  cursor: pointer;
  -webkit-box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.4);
  margin-bottom: 40px;
  overflow: hidden;
  position: relative;

  .content {
    width: 25vw;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px 40px;

    .title {
      font-size: 20px;
      color: #333333;
      font-weight: 600;
    }

    .sub-title {
      margin-top: 24px;
      font-size: 14px;
      color: #888888;
    }

    .tag {
      margin-top: 30px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #333;
      color: #ffffff;
      padding: 12px 20px;
      border-radius: 9999px;
    }
  }

  .hover-indicator {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    background-color: #333333aa;
  }

  @media (max-width: 1440px) {
    .content {
      .title {
        font-size: 16px;
      }

      .sub-title {
        display: none;
        font-size: 12px;
      }

      .tag {
        font-size: 12px;
        padding: 8px 20px;
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 1020px) {
    width: 40vw;
    height: 40vw;

    .content {
      width: 40vw;
      padding: 20px 40px;

      .title {
        font-size: 20px;
        font-weight: 600;
      }

      .sub-title {
        display: block;
        margin-top: 24px;
        font-size: 14px;
      }

      .tag {
        margin-top: 30px;
        font-size: 16px;
        padding: 12px 20px;
      }
    }
  }

  @media (max-width: 760px) {
    .content {
      .title {
        font-size: 16px;
      }

      .sub-title {
        display: none;
        font-size: 12px;
      }

      .tag {
        font-size: 12px;
        padding: 8px 20px;
        margin-top: 20px;
      }
    }
  }

  @media (max-width: 540px) {
    width: 60vw;
    height: 60vw;

    .content {
      width: 60vw;
    }
  }

  @media (max-width: 380px) {
    width: 80vw;
    height: 80vw;

    .content {
      width: 80vw;
    }
  }

  &:hover {
    transform: translateX(-1px) translateY(-1px);
    transition-duration: 500ms;

    .hover-indicator {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
