.login-root {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.login-root .logo-section {
  margin-bottom: 72px;
}

.login-root .input-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.login-root .input-section .login-input {
  width: 440px;
  margin-top: 18px;
}

.login-root .input-section .login-btn {
  margin-top: 48px;
  width: 440px;
  padding: 16px;
  border-radius: 2px;
  background-color: #2527ff;
  font-size: 18px;
  color: #ffffff;
}
