.cselect-root {
  overflow: visible;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: #222222;
  font-size: 20px;
  position: relative;
}

.cselect-root .selected-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 340px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 16px;
  border: solid 1px #333333;
  background-color: #ffffff;
}

.cselect-root .option-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  top: 62px;
  width: 340px;
  z-index: 10;
}

.cselect-root .option-section .option-item {
  padding: 16px;
  border: solid 1px #333333;
  background-color: #ffffff;
}
