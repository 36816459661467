.qna-detail-root {
  padding: 44px 60px 64px 60px;
  background-color: #f5f5f5;
  border-radius: 10px;
  width: 1024px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 1;
}

.qna-detail-root .delete-button {
  -ms-flex-item-align: end;
      align-self: flex-end;
  width: 64px;
  height: 42px;
  background-color: #fff;
  color: #333333;
  border: solid 1px #333;
}

.qna-detail-root .section-label {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 16px;
}

.qna-detail-root .title-section {
  margin-bottom: 36px;
}

.qna-detail-root .title-section .title-content {
  padding: 8px 20px;
  color: #888888;
  font-size: 20px;
  width: 100%;
  background-color: #fff;
  border: solid 1px #333;
}

.qna-detail-root .content-section {
  margin-bottom: 36px;
}

.qna-detail-root .content-section .content-content {
  min-height: 200px;
  height: auto;
  padding: 8px 20px;
  color: #888888;
  font-size: 20px;
  width: 100%;
  background-color: #fff;
  border: solid 1px #333;
}

.qna-detail-root .reply-section {
  margin-bottom: 36px;
}

.qna-detail-root .reply-section .reply-input {
  min-height: 200px;
  height: auto;
  padding: 8px 20px;
  color: #888888;
  font-size: 20px;
  width: 100%;
  background-color: #fff;
  border: solid 1px #333;
  vertical-align: top;
}

.qna-detail-root .check-box-wrapper {
  -ms-flex-item-align: center;
      align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.qna-detail-root .check-box-wrapper .label {
  margin-left: 12px;
  font-size: 20px;
}

.qna-detail-root .button-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 74px;
  margin-top: 48px;
}

.qna-detail-root .button-wrapper button {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 20px;
}

.qna-detail-root .button-wrapper button:first-child {
  margin-right: 12px;
  background-color: #fff;
  border: solid 1px #333;
  color: #333;
}

.qna-detail-root .button-wrapper button:last-child {
  background-color: #333;
  color: #fff;
}
