.section-item-root {
  overflow: visible;
  margin-top: 100px;

  .title-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px;

    .title {
      font-size: 26px;
      font-weight: bold;
      color: #333333;
    }

    .btn-section {
      display: flex;
      flex-direction: row;
      align-items: center;

      .delete-btn {
        margin-left: 20px;
        padding: 8px 20px;
        border-radius: 2px;
        border: solid 1px #333;
        background-color: #fff;
      }
    }
  }

  .item-list-section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .item-root {
      margin: 15px;
      padding: 40px 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: solid 1px #707070;
      background-color: #fff;

      .title {
        font-size: 26px;
        font-weight: bold;
        color: #333333;
      }

      .sub-title {
        font-size: 18px;
        font-weight: bold;
        color: #888888;
      }
    }

    .add-item {
      position: relative;
      overflow: visible;
      background-color: #f5f5f5;

      input {
        border: 1px solid #333333;
        padding: 4px 12px;
      }

      .sub-name-input {
        margin-top: 8px;
      }

      .wing {
        width: 60px;
        cursor: pointer;
        height: calc(100% + 2px);
        background-color: #333333;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -60px;
        top: -1px;
      }
    }
  }
}
