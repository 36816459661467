.qna-detail-root {
  padding: 44px 60px 64px 60px;
  background-color: #f5f5f5;
  border-radius: 10px;
  width: 1024px;
  display: flex;
  flex-direction: column;
  z-index: 1;

  .delete-button {
    align-self: flex-end;
    width: 64px;
    height: 42px;
    background-color: #fff;
    color: #333333;
    border: solid 1px #333;
  }

  .section-label {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 16px;
  }

  .title-section {
    margin-bottom: 36px;

    .title-content {
      padding: 8px 20px;
      color: #888888;
      font-size: 20px;
      width: 100%;
      background-color: #fff;
      border: solid 1px #333;
    }
  }

  .content-section {
    margin-bottom: 36px;

    .content-content {
      min-height: 200px;
      height: auto;
      padding: 8px 20px;
      color: #888888;
      font-size: 20px;
      width: 100%;
      background-color: #fff;
      border: solid 1px #333;
    }
  }

  .reply-section {
    margin-bottom: 36px;

    .reply-input {
      min-height: 200px;
      height: auto;
      padding: 8px 20px;
      color: #888888;
      font-size: 20px;
      width: 100%;
      background-color: #fff;
      border: solid 1px #333;
      vertical-align: top;
    }
  }

  .check-box-wrapper {
    align-self: center;
    display: flex;
    align-items: center;

    .label {
      margin-left: 12px;
      font-size: 20px;
    }
  }

  .button-wrapper {
    display: flex;
    height: 74px;
    margin-top: 48px;
    button {
      flex: 1;
      font-size: 20px;

      &:first-child {
        margin-right: 12px;
        background-color: #fff;
        border: solid 1px #333;
        color: #333;
      }

      &:last-child {
        background-color: #333;
        color: #fff;
      }
    }
  }
}
