.card-news-root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 25vw;
  height: 20vw;
  border-radius: 2vw;
  cursor: pointer;
  -webkit-box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.4);
  margin-bottom: 40px;
  overflow: hidden;
  position: relative;
}

.card-news-root .content {
  width: 25vw;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 20px 40px;
}

.card-news-root .content .title {
  font-size: 20px;
  color: #333333;
  font-weight: 600;
}

.card-news-root .content .sub-title {
  margin-top: 24px;
  font-size: 14px;
  color: #888888;
}

.card-news-root .content .tag {
  margin-top: 30px;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #333;
  color: #ffffff;
  padding: 12px 20px;
  border-radius: 9999px;
}

.card-news-root .hover-indicator {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  background-color: #333333aa;
}

@media (max-width: 1440px) {
  .card-news-root .content .title {
    font-size: 16px;
  }
  .card-news-root .content .sub-title {
    display: none;
    font-size: 12px;
  }
  .card-news-root .content .tag {
    font-size: 12px;
    padding: 8px 20px;
    margin-top: 20px;
  }
}

@media (max-width: 1020px) {
  .card-news-root {
    width: 40vw;
    height: 40vw;
  }
  .card-news-root .content {
    width: 40vw;
    padding: 20px 40px;
  }
  .card-news-root .content .title {
    font-size: 20px;
    font-weight: 600;
  }
  .card-news-root .content .sub-title {
    display: block;
    margin-top: 24px;
    font-size: 14px;
  }
  .card-news-root .content .tag {
    margin-top: 30px;
    font-size: 16px;
    padding: 12px 20px;
  }
}

@media (max-width: 760px) {
  .card-news-root .content .title {
    font-size: 16px;
  }
  .card-news-root .content .sub-title {
    display: none;
    font-size: 12px;
  }
  .card-news-root .content .tag {
    font-size: 12px;
    padding: 8px 20px;
    margin-top: 20px;
  }
}

@media (max-width: 540px) {
  .card-news-root {
    width: 60vw;
    height: 60vw;
  }
  .card-news-root .content {
    width: 60vw;
  }
}

@media (max-width: 380px) {
  .card-news-root {
    width: 80vw;
    height: 80vw;
  }
  .card-news-root .content {
    width: 80vw;
  }
}

.card-news-root:hover {
  -webkit-transform: translateX(-1px) translateY(-1px);
          transform: translateX(-1px) translateY(-1px);
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
}

.card-news-root:hover .hover-indicator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
