.card-root {
  width: 20vw;
  height: 20vw;
  border-radius: 2vw;
  -webkit-box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.4);
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  padding: 40px;
  text-align: center;
}

.card-root .title {
  color: #333333;
  font-size: 24px;
  font-weight: bold;
}

.card-root .sub-title {
  color: #888888;
  font-size: 16px;
}

.card-root .ai-button {
  cursor: pointer;
  padding: 16px;
  border: 2px solid #444af5;
  border-radius: 9999px;
  font-size: 18px;
  color: #444af5;
}

.card-root .web-app-button {
  cursor: pointer;
  padding: 16px;
  border: 2px solid #444af5;
  border-radius: 9999px;
  font-size: 18px;
  color: #ffffff;
  background-color: #444af5;
}

@media (max-width: 1440px) {
  .card-root .title {
    font-size: 16px;
  }
  .card-root .sub-title {
    font-size: 12px;
  }
  .card-root .ai-button {
    font-size: 16px;
    padding: 8px 20px;
    margin-top: 20px;
  }
  .card-root .web-app-button {
    font-size: 16px;
    padding: 8px 20px;
    margin-top: 20px;
  }
}

@media (max-width: 1020px) {
  .card-root {
    width: 40vw;
    height: 40vw;
  }
  .card-root .title {
    font-size: 24px;
  }
  .card-root .sub-title {
    font-size: 16px;
  }
  .card-root .ai-button {
    font-size: 18px;
    padding: 8px 20px;
    margin-top: 20px;
  }
  .card-root .web-app-button {
    font-size: 16px;
    padding: 8px 20px;
    margin-top: 20px;
  }
}

@media (max-width: 540px) {
  .card-root {
    width: 60vw;
    height: 60vw;
  }
  .card-root .title {
    font-size: 20px;
  }
  .card-root .sub-title {
    font-size: 16px;
  }
  .card-root .ai-button {
    font-size: 16px;
  }
  .card-root .web-app-button {
    font-size: 16px;
  }
}

@media (max-width: 380px) {
  .card-root {
    width: 80vw;
    height: 80vw;
  }
}
