.request-root {
  flex: 1;
  padding: 60px;

  .search-section {
    display: flex;
    .name-input {
      display: flex;
      flex: 1;

      .csearch-input-section {
        display: flex;
        flex: 1;
        .search-input {
          flex: 1;
        }
      }
    }
  }

  .list-section {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    border: 1px solid #dbdbdb;

    .header-article {
      display: flex;
      flex-direction: row;
      background-color: #dedede;
      padding: 16px 0px;

      .header-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        font-size: 20px;
        font-weight: bold;
      }
    }

    .body-article {
      display: flex;
      flex-direction: column;
      .list-item {
        display: flex;
        flex-direction: row;
        background-color: #ffffff;
        border-bottom: 1px solid #dedede;
        padding: 16px 0px;

        .item-label {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
        }
      }
    }
  }
}
