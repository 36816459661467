.section-item-root {
  overflow: visible;
  margin-top: 100px;
}

.section-item-root .title-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 15px;
}

.section-item-root .title-section .title {
  font-size: 26px;
  font-weight: bold;
  color: #333333;
}

.section-item-root .title-section .btn-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.section-item-root .title-section .btn-section .delete-btn {
  margin-left: 20px;
  padding: 8px 20px;
  border-radius: 2px;
  border: solid 1px #333;
  background-color: #fff;
}

.section-item-root .item-list-section {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4];
      grid-template-columns: repeat(4, 1fr);
}

.section-item-root .item-list-section .item-root {
  margin: 15px;
  padding: 40px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: solid 1px #707070;
  background-color: #fff;
}

.section-item-root .item-list-section .item-root .title {
  font-size: 26px;
  font-weight: bold;
  color: #333333;
}

.section-item-root .item-list-section .item-root .sub-title {
  font-size: 18px;
  font-weight: bold;
  color: #888888;
}

.section-item-root .item-list-section .add-item {
  position: relative;
  overflow: visible;
  background-color: #f5f5f5;
}

.section-item-root .item-list-section .add-item input {
  border: 1px solid #333333;
  padding: 4px 12px;
}

.section-item-root .item-list-section .add-item .sub-name-input {
  margin-top: 8px;
}

.section-item-root .item-list-section .add-item .wing {
  width: 60px;
  cursor: pointer;
  height: calc(100% + 2px);
  background-color: #333333;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  right: -60px;
  top: -1px;
}
