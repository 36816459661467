.group-add-popup-root {
  padding: 60px;
  background-color: #f5f5f5;
  border-radius: 10px;
  overflow: visible;
}

.group-add-popup-root .input-section {
  overflow: visible;
}

.group-add-popup-root .input-section:nth-child(1) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.group-add-popup-root .input-section .input-item {
  margin-top: 24px;
  overflow: visible;
}

.group-add-popup-root .input-section .input-item .input-label {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.group-add-popup-root .input-section .input-item .input {
  width: 360px;
  height: 60px;
}

.group-add-popup-root .button-section {
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.group-add-popup-root .button-section .cancel-btn {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-right: 24px;
  border-radius: 2px;
  border: solid 1px #333;
  background-color: #fff;
  height: 60px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.group-add-popup-root .button-section .add-btn {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border-radius: 2px;
  background-color: #333;
  border-radius: 2px;
  border: solid 1px #333;
  height: 60px;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}
