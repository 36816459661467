.cselect-root {
  overflow: visible;
  display: flex;
  flex-direction: column;
  color: #222222;
  font-size: 20px;
  position: relative;

  .selected-section {
    display: flex;
    flex-direction: row;
    width: 340px;
    justify-content: space-between;
    padding: 16px;
    border: solid 1px #333333;
    background-color: #ffffff;
  }

  .option-section {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 62px;
    width: 340px;
    z-index: 10;

    .option-item {
      padding: 16px;
      border: solid 1px #333333;
      background-color: #ffffff;
    }
  }
}
