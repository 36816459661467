.card-news-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.card-news-section .title-article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 150px;
}

.card-news-section .title-article .title {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 32px;
  font-weight: 600;
}

.card-news-section .title-article .sub-title {
  font-size: 24px;
  color: #888888;
  margin: 2rem;
}

@media (max-width: 760px) {
  .card-news-section .title-article {
    margin-top: 40px;
  }
  .card-news-section .title-article .title {
    font-size: 24px;
  }
  .card-news-section .title-article .sub-title {
    font-size: 18px;
  }
}

@media (max-width: 380px) {
  .card-news-section .title-article .title {
    font-size: 20px;
  }
  .card-news-section .title-article .sub-title {
    font-size: 14px;
  }
}

.card-news-section .card-news-row {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1020px) {
  .card-news-section .card-news-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
}

.card-news-section .card-news-row .card-news-column {
  margin: 0px 20px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
