.calc-item-root {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 60px;

  .intro-section {
    white-space: pre-line;

    .title {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
    }

    .content {
      font-size: 18px;
      color: #333333;
      margin-top: 20px;
      padding: 28px;
      border: solid 1px #b5b5b5;
      background-color: #fff;
    }
  }

  .item-list-section {
    .section-add-btn {
      display: flex;
      background-color: #f5f5f5;
      border: 1px solid #707070;
      justify-content: center;
      align-items: center;
      padding: 80px;
      margin: 15px;
      margin-top: 90px;
      font-size: 30px;
      color: #333333;
    }
  }
}
