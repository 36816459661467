.common-code-root {
  flex: 1;

  .search-section {
    display: flex;
    .name-input {
      display: flex;
      flex: 1;

      .csearch-input-section {
        display: flex;
        flex: 1;
        .search-input {
          flex: 1;
        }
      }
    }
  }

  .header-section {
    padding: 30px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 28px;
      font-weight: 800;
      color: #333333;
    }

    .add-btn {
      padding: 12px 32px;
      background-color: #333333;
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
    }
  }

  .content-section {
    padding: 60px;

    .list-section {
      display: flex;
      flex-direction: column;
      border: 1px solid #dbdbdb;

      .header-article {
        display: flex;
        flex-direction: row;
        background-color: #dedede;
        padding: 16px 0px;

        .header-item {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          font-size: 20px;
          font-weight: bold;
        }
      }

      .body-article {
        display: flex;
        flex-direction: column;
        .list-item {
          display: flex;
          flex-direction: row;
          background-color: #ffffff;
          border-bottom: 1px solid #dedede;
          padding: 16px 0px;

          .item-label {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
          }
        }
      }
    }
  }
}
