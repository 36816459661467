.apply-detail-root {
  padding: 60px;
  .info-section {
    margin-top: 48px;

    .title {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
    }

    .content {
      display: block;
      margin-bottom: 20px;
      font-size: 16px;
      color: #888888;
    }
  }
}
