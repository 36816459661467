.main-banner-root {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.main-banner-root .content-title-section {
  padding: 30px;
  font-size: 28px;
  font-weight: 800;
  color: #333333;
  background-color: #ffffff;
}

.main-banner-root .preview-section {
  height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 60px;
}

.main-banner-root .preview-section .image-video-article {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.main-banner-root .preview-section .image-video-article .title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.main-banner-root .preview-section .image-video-article .image-video {
  margin-top: 24px;
}

.main-banner-root .preview-section .image-video-article img,
.main-banner-root .preview-section .image-video-article video {
  width: 100%;
}

.main-banner-root .preview-section .url-article {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.main-banner-root .preview-section .url-article .title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.main-banner-root .preview-section .url-article .url {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-top: 24px;
}

.main-banner-root .preview-section .url-article .url .url-input {
  height: 48px;
  margin-right: 16px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.main-banner-root .preview-section .url-article .url .url-btn {
  width: 110px;
  height: 48px;
  border-radius: 2px;
  border: solid 1px #707070;
  background-color: #ffffff;
  font-size: 20px;
  color: #333333;
}

.main-banner-root .list-section {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 60px;
}

.main-banner-root .list-section .file-list {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-right: 60px;
}

.main-banner-root .list-section .file-list .search-article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.main-banner-root .list-section .file-list .search-article .title {
  font-size: 24px;
  color: #333333;
}

.main-banner-root .list-section .file-list .search-article .date-input {
  height: 48px;
  width: 200px;
  margin-left: 12px;
}

.main-banner-root .list-section .file-list .search-article .file-name-input {
  height: 48px;
  margin-right: 12px;
  width: 300px;
}

.main-banner-root .list-section .file-list .search-article .search-btn {
  width: 110px;
  height: 48px;
  border-radius: 2px;
  border: solid 1px #707070;
  background-color: #fff;
  font-size: 20px;
  color: #333333;
}

.main-banner-root .list-section .file-list .list-article .list-header {
  height: 56px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #dedede;
  margin-top: 20px;
  border: 1px solid #b5b5b5;
}

.main-banner-root .list-section .file-list .list-article .list-header .list-header-item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main-banner-root .list-section .file-list .list-article .list-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.main-banner-root .list-section .file-list .list-article .list-body .list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: 60px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fff;
  border: 1px solid #c6c6c6;
}

.main-banner-root .list-section .file-list .list-article .list-body .list-item .list-item-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.main-banner-root .list-section .file-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 420px;
  height: 420px;
  padding: 20px;
  border: solid 1px #b5b5b5;
  background-color: #fff;
}

.main-banner-root .list-section .file-input .input-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 20px;
  color: #333333;
  margin-top: 24px;
}

.main-banner-root .list-section .file-input .input-item .input {
  height: 48px;
  margin-top: 14px;
}

.main-banner-root .list-section .file-input .input-btn {
  margin-top: 24px;
  height: 48px;
  border-radius: 2px;
  border: solid 1px #707070;
  background-color: #ffffff;
  font-size: 20px;
  color: #333333;
}
