.csearch-root .csearch-input-section {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.csearch-root .csearch-input-section .search-input {
  width: 400px;
  height: 60px;
  padding: 16px;
  border: solid 4px #333;
  background-color: #fff;
}

.csearch-root .csearch-input-section .search-btn {
  width: 120px;
  height: 60px;
  border: solid 4px #333;
  background-color: #333;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}

.csearch-root .csearch-input-section .delete-btn {
  position: absolute;
  background-color: #ffffff;
  right: 140px;
}
