.search-post-card-root {
  width: 480px;
  height: 300px;
  position: relative;
  background-color: #33333333;
  margin: 10px;
}

.search-post-card-root .cover-section {
  height: 100%;
  padding: 24px;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.search-post-card-root .cover-section .tag {
  display: inline-block;
  padding: 4px 24px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 9px;
  color: #ffffff;
}

.search-post-card-root .cover-section .title-article .title {
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
}

.search-post-card-root .cover-section .title-article .sub-title {
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
}
