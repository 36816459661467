.search-post-card-root {
  width: 480px;
  height: 300px;
  position: relative;
  background-color: #33333333;
  margin: 10px;

  .cover-section {
    height: 100%;
    padding: 24px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .tag {
      display: inline-block;
      padding: 4px 24px;
      border-radius: 12px;
      background-color: rgba(255, 255, 255, 0.2);
      font-size: 9px;
      color: #ffffff;
    }

    .title-article {
      .title {
        font-size: 22px;
        font-weight: bold;
        color: #ffffff;
      }

      .sub-title {
        font-size: 22px;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
}
