.qna-root {
  flex: 1;
  display: flex;
  flex-direction: column;

  .title-section {
    background-color: #fff;
    height: 120px;
    padding-bottom: 30px;
    padding-left: 60px;

    .title {
      font-size: 28px;
      font-weight: bolder;
      color: #333333;
      margin-top: 60px;
    }
  }

  .content-section {
    padding: 60px;

    .list-section {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      .qna-card-item {
        background-color: #fff;
        padding: 36px 20px;
        margin: 20px;

        .qna-title {
          font-size: 26px;
          font-weight: bold;
          margin-bottom: 12px;
          color: #333333;
        }

        .content {
          font-size: 20px;
          font-weight: 500;
          color: #888888;
          height: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .answer-button {
          border: solid 1px #b5b5b5;
          background-color: #f5f5f5;
          color: #333333;
          font-size: 20px;
          text-align: center;
          height: 48px;
          width: 100%;
        }
      }
    }

    .add-button {
      position: fixed;
      bottom: 60px;
      right: 80px;
      width: 160px;
      height: 160px;
      border-radius: 80px;
      background-color: #333333;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 28px;
      }
    }
  }
}
