.post-root {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 60px;
}

.post-root .input-section {
  overflow: visible;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.post-root .input-section .space {
  width: 60px;
}

.post-root .input-section .post-input {
  width: 600px;
}

.post-root .post-list-section {
  margin-top: 28px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  -ms-grid-rows: auto;
      grid-template-rows: auto;
}
