.gnb-root {
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #ffffff;
}

.gnb-root .gnb-list-section {
  width: 300px;
  margin-top: 120px;
}

.gnb-root .gnb-list-section .gnb-item {
  cursor: pointer;
  color: #888888;
  font-size: 18px;
  padding: 24px 0px;
  padding-left: 60px;
  position: relative;
}

.gnb-root .gnb-list-section .gnb-item.selected {
  color: #2527ff;
  background-color: #2527ff22;
}

.gnb-root .gnb-list-section .gnb-item.selected .bar {
  width: 8px;
  height: 70px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #2527ff;
}
