.admin-create-root {
  flex: 1;

  .title-section {
    height: 120px;
    padding: 0px 60px 30px 60px;
    display: flex;
    background-color: #fff;
    justify-content: space-between;
    align-items: flex-end;

    .title {
      color: #333333;
      font-weight: bolder;
      font-size: 28px;
    }

    .button-wrapper {
      display: flex;

      button {
        font-size: 20px;
        height: 60px;
        font-weight: bold;
        padding: 0px 20px;
      }

      .cancel-button {
        border: solid 1px #333333;
        color: #333333;
        background-color: #fff;
        margin-right: 8px;
      }
    }
  }

  .content-section {
    padding: 60px;
    overflow: visible;

    .admin-form {
      display: flex;
      overflow: visible;

      .input-section {
        margin-right: 144px;
        flex: 1;
        overflow: visible;

        .input-item {
          margin-bottom: 48px;
          overflow: visible;

          .input-label {
            font-size: 24px;
            font-weight: bold;
            color: #333333;
            margin-bottom: 20px;
          }

          .input {
            &::placeholder {
              color: #888888;
              font-size: 16px;
            }
            padding: 16px 24px;
            width: 100%;
            border: solid 2px #b5b5b5;
            background-color: #fff;
          }
        }
      }

      .profile-section {
        flex: 1;
        overflow: visible;

        .input-item {
          margin-bottom: 48px;
          overflow: visible;

          .input-label {
            font-size: 24px;
            font-weight: bold;
            color: #333333;
            margin-bottom: 20px;
          }

          .profile-wrapper {
            display: flex;

            #profile-input {
              display: none;
            }

            img {
              width: 56px;
              height: 56px;
              border-radius: 28px;
            }

            label {
              border: solid 1px #333;
              background-color: #f5f5f5;
              font-size: 16px;
              color: #333333;
              text-align: center;
              padding: 8px 12px;
              margin-left: 24px;
            }
          }
        }

        .store-button {
          border-radius: 2px;
          background-color: #333333;
          color: #ffffff;
          width: 110px;
          height: 60px;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
}
