.group-add-popup-root {
  padding: 60px;
  background-color: #f5f5f5;
  border-radius: 10px;
  overflow: visible;

  .input-section {
    overflow: visible;

    &:nth-child(1) {
      display: flex;
      flex-direction: row;
    }

    .input-item {
      margin-top: 24px;
      overflow: visible;

      .input-label {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
      }

      .input {
        width: 360px;
        height: 60px;
      }
    }
  }

  .button-section {
    margin-top: 24px;
    display: flex;
    flex-direction: row;

    .cancel-btn {
      flex: 1;
      margin-right: 24px;
      border-radius: 2px;
      border: solid 1px #333;
      background-color: #fff;
      height: 60px;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }

    .add-btn {
      flex: 1;
      border-radius: 2px;
      background-color: #333;
      border-radius: 2px;
      border: solid 1px #333;
      height: 60px;
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
