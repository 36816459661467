.card-root {
  width: 20vw;
  height: 20vw;
  border-radius: 2vw;
  -webkit-box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.4);
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  padding: 40px;
  text-align: center;

  .title {
    color: #333333;
    font-size: 24px;
    font-weight: bold;
  }

  .sub-title {
    color: #888888;
    font-size: 16px;
  }

  .ai-button {
    cursor: pointer;
    padding: 16px;
    border: 2px solid #444af5;
    border-radius: 9999px;
    font-size: 18px;
    color: #444af5;
  }

  .web-app-button {
    cursor: pointer;
    padding: 16px;
    border: 2px solid #444af5;
    border-radius: 9999px;
    font-size: 18px;
    color: #ffffff;
    background-color: #444af5;
  }

  @media (max-width: 1440px) {
    .title {
      font-size: 16px;
    }

    .sub-title {
      font-size: 12px;
    }

    .ai-button {
      font-size: 16px;
      padding: 8px 20px;
      margin-top: 20px;
    }

    .web-app-button {
      font-size: 16px;
      padding: 8px 20px;
      margin-top: 20px;
    }
  }

  @media (max-width: 1020px) {
    width: 40vw;
    height: 40vw;

    .title {
      font-size: 24px;
    }

    .sub-title {
      font-size: 16px;
    }

    .ai-button {
      font-size: 18px;
      padding: 8px 20px;
      margin-top: 20px;
    }

    .web-app-button {
      font-size: 16px;
      padding: 8px 20px;
      margin-top: 20px;
    }
  }

  @media (max-width: 540px) {
    width: 60vw;
    height: 60vw;

    .title {
      font-size: 20px;
    }

    .sub-title {
      font-size: 16px;
    }

    .ai-button {
      font-size: 16px;
    }

    .web-app-button {
      font-size: 16px;
    }
  }

  @media (max-width: 380px) {
    width: 80vw;
    height: 80vw;
  }
}
