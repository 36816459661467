.login-root {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo-section {
    margin-bottom: 72px;
  }

  .input-section {
    display: flex;
    flex-direction: column;

    .login-input {
      width: 440px;
      margin-top: 18px;
    }

    .login-btn {
      margin-top: 48px;
      width: 440px;
      padding: 16px;
      border-radius: 2px;
      background-color: #2527ff;
      font-size: 18px;
      color: #ffffff;
    }
  }
}
