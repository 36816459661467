.common-code-root {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.common-code-root .search-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.common-code-root .search-section .name-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.common-code-root .search-section .name-input .csearch-input-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.common-code-root .search-section .name-input .csearch-input-section .search-input {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.common-code-root .header-section {
  padding: 30px;
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.common-code-root .header-section .title {
  font-size: 28px;
  font-weight: 800;
  color: #333333;
}

.common-code-root .header-section .add-btn {
  padding: 12px 32px;
  background-color: #333333;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}

.common-code-root .content-section {
  padding: 60px;
}

.common-code-root .content-section .list-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid #dbdbdb;
}

.common-code-root .content-section .list-section .header-article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: #dedede;
  padding: 16px 0px;
}

.common-code-root .content-section .list-section .header-article .header-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 20px;
  font-weight: bold;
}

.common-code-root .content-section .list-section .body-article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.common-code-root .content-section .list-section .body-article .list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: #ffffff;
  border-bottom: 1px solid #dedede;
  padding: 16px 0px;
}

.common-code-root .content-section .list-section .body-article .list-item .item-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
