.admin-root {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.admin-root .title-section {
  height: 120px;
  background-color: #fff;
}

.admin-root .title-section .title {
  color: #333333;
  font-size: 28px;
  margin-left: 60px;
  margin-top: 60px;
  font-weight: bolder;
}

.admin-root .content-section {
  padding: 60px;
}

.admin-root .content-section .search-input {
  width: 100%;
}

.admin-root .content-section .list-section {
  margin-top: 60px;
}

.admin-root .content-section .list-section .list-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #dedede;
  color: #333333;
  padding: 16px 0px;
  border: solid 1px #b5b5b5;
}

.admin-root .content-section .list-section .list-header .header-col {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.admin-root .content-section .list-section .list-body {
  background-color: #fff;
  border: solid 1px #b5b5b5;
}

.admin-root .content-section .list-section .list-body .list-row {
  padding: 10px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.admin-root .content-section .list-section .list-body .list-row:not(:last-child) {
  border-bottom: solid 1px #b5b5b5;
}

.admin-root .content-section .list-section .list-body .list-row .row-item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  font-size: 16px;
}

.admin-root .content-section .list-section .list-body .list-row .row-item img {
  width: 36px;
  height: 36px;
  border-radius: 18px;
}

.admin-root .content-section .create-button {
  margin-top: 40px;
  float: right;
  padding: 20px;
  background-color: #333333;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}
