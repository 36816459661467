.apply-detail-root {
  padding: 60px;
}

.apply-detail-root .info-section {
  margin-top: 48px;
}

.apply-detail-root .info-section .title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.apply-detail-root .info-section .content {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  color: #888888;
}
