.post-select-root {
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 60px;
  position: relative;

  .input-section {
    display: flex;
    flex-direction: row;

    .space {
      width: 60px;
    }
  }

  .post-list-container {
    max-height: 500px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    margin-top: 48px;
  }
}
