.csearch-root {
  .csearch-input-section {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .search-input {
      width: 400px;
      height: 60px;
      padding: 16px;
      border: solid 4px #333;
      background-color: #fff;
    }

    .search-btn {
      width: 120px;
      height: 60px;
      border: solid 4px #333;
      background-color: #333;
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
    }

    .delete-btn {
      position: absolute;
      background-color: #ffffff;
      right: 140px;
    }
  }
}
